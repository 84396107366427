<template>
  <div>
    <b-container fluid>
      <b-row class="justify-content-center mt-5">
        <b-col cols="10" sm="8" md="6" lg="4">
          <h1>Login</h1>
          <b-form @submit.prevent="handleLogin">
            <b-form-group label="Email">
              <b-form-input type="email" v-model="email" placeholder="Enter email" />
            </b-form-group>
            <b-form-group label="Password">
              <b-form-input type="password" v-model="password" placeholder="Enter password" />
            </b-form-group>
            <b-button type="submit" variant="primary" block>Login</b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import 'firebase/auth';
export default {
  data() {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    async handleLogin() {
      await this.$store.dispatch('login', { email: this.email, password: this.password });
      this.$router.push('/');
    }
  }
}
</script>